import React from 'react';
import { Col, Image } from 'antd';

/**CORE IMPORTS */
import Logo from '@core_assets/images/company_logo.png';

function CampanyLogo() {
  return (
    <Col className="w-100 h-100 d-flex align-items-center justify-content-end" md={{ span: 12 }}>
      <Image width={200} src={Logo} preview={false} />
    </Col>
  );
}

export default CampanyLogo;
