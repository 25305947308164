import { Routes } from '@core_routing/';

const LayoutController = ({ store, history, setSelectedKeys }) => {
  const setLanguage = async (locale) => {
    console.log(store.toJSON(), 'TSET');
    await store.globalState.setLanguage(locale);
  };

  const handleLogout = async () => {
    let response = await store.login.logout();

    if (response) {
      //history.push(Routes.LOGIN_ROUTE)
      window.location = Routes.LOGIN_ROUTE;
    }
  };

  const applyActiveClass = (locale) => {
    return store.globalState.language === locale && 'active';
  };

  const setDefaultMenu = async () => {
    if (history.location.pathname === Routes.HOME_ROUTE) {
      setSelectedKeys(['1']);
    } else if (history.location.pathname === Routes.USER_MANAGEMENT_ROUTE) {
      setSelectedKeys(['2']);
    } else if (history.location.pathname === Routes.TROPICAL_HOLIDAY_ROUTE) {
    setSelectedKeys(['3']);
  } else if (history.location.pathname === Routes.TEST_MODULE_ROUTE) {
    setSelectedKeys(['4']);
  } 
  };

  return { setLanguage, handleLogout, applyActiveClass, setDefaultMenu };
};

export default LayoutController;
