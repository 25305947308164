import React from 'react';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';

class Printable extends React.Component {
  render() {
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <Button
              className="shadow-lg"
              size="large"
              style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
                zIndex: 9999,
                width: 60,
                height: 60
              }}
              type="primary"
              shape="circle"
              icon={<PrinterOutlined style={{ fontSize: 25 }} />}
            />
          )}
          content={() => this.componentRef}
        />

        <div ref={(el) => (this.componentRef = el)}>
          <table className="w-100">
            <thead id="header-print">
              <tr>
                <td>
                  <div class="header-space">&nbsp;</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>{this.props.children}</div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div class="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="header">HEADER</div>
          <div class="footer">FOOTER</div>
        </div>
      </>
    );
  }
}

export default Printable;
