import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

//MobX-State-Tree
import { Provider } from 'mobx-react';
import RootStore from './core/state_management/RootStore';
import LoginModel from './core/modules/Login/model/LoginModel';

//Translation i18next
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import translationEng from './core/translations/en/translation.json';
import translationDe from './core/translations/de/translation.json';

const store = RootStore.create({
  login: LoginModel.create({
    id: '0',
    email: '',
    type: '',
    firstName: '',
    lastName: '',
    user_type_id: 0,
    fingerprint: '',
    phoneNumber: '',
    rememberMe: false,
    isLoggedIn: false,
    isLoading: true
  })
});

store.initialize();

i18next.init({
  lng: store.globalState.language, // language to use
  resources: {
    en: {
      common: translationEng // 'common' is our custom namespace
    },
    de: {
      common: translationDe
    }
  },
  fallbackLng: 'de',
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  react: {
    wait: true
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
