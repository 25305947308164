import React, { Suspense } from 'react';
import { Card, Grid } from 'antd';

import './styles.css';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

import { ResetPasswordForm } from '@core_modules/ResetPassword/components/ResetPassword';

const { useBreakpoint } = Grid;

function ResetPassword() {
  const screens = useBreakpoint();

  return (
    <Suspense fallback={FallBackLoaders.EmptyLoader}>
      <Card className={`shadow-lg ${screens.xs ? 'w-100 mx-4' : 'w-75'} p-4`}>
        <ResetPasswordForm />
      </Card>
    </Suspense>
  );
}

export default ResetPassword;
