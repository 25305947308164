import React from 'react';
import { useTranslation } from 'react-i18next';

/**CORE IMPORTS */
import { ExactTitle } from '@core_common/components';

function TropicalHoliday() {
  const { t } = useTranslation('common');

  return (
    <div>
      <ExactTitle level={3} text={t('Tropical Holiday')} />
    </div>
  );
}

export default TropicalHoliday;
