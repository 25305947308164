import React, { Suspense } from 'react';
import { Col, Grid, Row } from 'antd';

import './styles.css';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { CompanyLogo } from '@core_modules/Layout/components/SignInLayout';

const { useBreakpoint } = Grid;

function SignInLayout({ children }) {
  const screens = useBreakpoint();

  const handleShowScreen = (screens) => {
    if (screens.xs) {
      return (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          {children}
        </div>
      );
    } else if (screens.sm && screens.md && !screens.lg) {
      return (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          {children}
        </div>
      );
    } else {
      return (
        <Row gutter={[150, 16]} className="w-100 h-100">
          <Suspense fallback={FallBackLoaders.EmptyLoader}>
            <CompanyLogo />
          </Suspense>

          <Col
            className="w-100 h-100 d-flex align-items-center justify-content-start"
            md={{ span: 12 }}
          >
            {children}
          </Col>
        </Row>
      );
    }
  };

  return (
    <div id="login-container" className="w-100 h-100">
      {handleShowScreen(screens)}
    </div>
  );
}

export default SignInLayout;
