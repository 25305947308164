import React from 'react';
import { PicCenterOutlined, DiffOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <PicCenterOutlined />,
    route: Routes.TROPICAL_HOLIDAY_ROUTE,
    menuName: 'Tropical Holiday'
  },
  {
    key: '4',
    icon: <DiffOutlined />,
    route: Routes.TEST_MODULE_ROUTE,
    menuName: 'Test Module'
  }
];

export default AppMenus;
