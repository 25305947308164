const AccountSettingsController = ({ store, form, setIsModalVisible }) => {
  const handleSetUpdateId = async () => {
    form.setFieldsValue(store.login);
    // await store.UserManagementUtilities.setUpdateId(store.login.id);
  };

  const handleSetSecurityDefaultValue = async () => {
    form.setFieldsValue({ type: store.login.type, email: store.login.email });
  };

  const handleToggleAccountSettingsModal = () => {
    setIsModalVisible((prev) => !prev);
  };

  return { handleSetUpdateId, handleSetSecurityDefaultValue, handleToggleAccountSettingsModal };
};

export default AccountSettingsController;
